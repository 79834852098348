<template>
  <div class="pane">
    <div class="header">
      <span class="title">{{ title }}</span>
      <Btn v-if="showCopy" icon="copy" @click="onCopy">复制代码</Btn>
    </div>
    <div :style="{ background: background }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Btn from "./Btn.vue";

export default {
  components: { Btn },
  props: {
    title: {
      type: String,
      default: ""
    },
    background: {
      type: String,
      default: "#ffffff00"
    },
    showCopy: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCopy() {
      this.$emit("copy");
    }
  }
};
</script>

<style lang="less" scoped>
.pane {
  margin-bottom: 18px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}
.title {
  font-weight: 500;
  font-size: 1em;
  color: #5786ff;
  font-weight: bold;
}
</style>

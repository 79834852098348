<template>
  <div>
    <!-- openapi 概览 -->
    <header style="padding: 0 12px;">
      <h1 class="title">{{ datasheetName }} 视图API</h1>
      <div class="flex item">
        <span class="desc-text">API：</span>
        <div class="flex">
          <span class="desc-text">{{ datasheetInfo.apiPath }} </span>
          <Btn icon="copy" @click="copy(baseURL + datasheetInfo.apiPath)">
            复制
          </Btn>
        </div>
      </div>
      <div class="flex item">
        <span class="desc-text">API Token：</span>
        <div class="flex">
          <span class="desc-text">{{ APIToken }}</span>
          <Btn icon="setting" @click="setApiToken">设置</Btn>
        </div>
      </div>
      <div class="flex item">
        <span class="desc-text">创建日期：{{ datasheetInfo.createTime }}</span>
        <span class="desc-text" style="margin-left: 50px">
          调用次数：{{ datasheetInfo.invokeTotal }} 次
        </span>
      </div>
    </header>

    <!-- 设置 API token Modal -->
    <a-modal
      title="API Token"
      width="420px"
      :visible="visibleSetToken"
      :footer="null"
      @cancel="onCancel"
    >
      <input
        :value="APIToken"
        readonly
        class="ant-input"
        style="margin-bottom: 36px"
      />
      <a-button type="primary" icon="copy" @click="copy(APIToken)">
        复制
      </a-button>
      <a-button
        :style="{ marginLeft: '12px' }"
        icon="redo"
        type="danger"
        @click="resetApiToken"
      >
        重置 Token
      </a-button>
    </a-modal>
  </div>
</template>

<script>
import cliptext from "copy-to-clipboard";
import store from "@/store";
import workspace from "@/views/datamodel/models/workspace";
import { updateApiToken } from "@/api/user";
import Btn from "./Btn.vue";
import openapi from "../openapi";

export default {
  components: { Btn: Btn },
  data() {
    return {
      visibleSetToken: false
    };
  },
  computed: {
    datasheetName() {
      const datasheet = workspace.currentDatasheet.node;
      return datasheet.nodeName;
    },
    APIToken() {
      if (!store.userInfo) return null;
      return store.userInfo.apiToken || "";
    },
    datasheetInfo() {
      return openapi.datasheetInfo || "";
    },
    baseURL() {
      return openapi.baseURL;
    }
  },
  methods: {
    /**
     * api token 相关
     */
    setApiToken() {
      this.visibleSetToken = true;
    },
    onCancel() {
      this.visibleSetToken = false;
    },
    resetApiToken() {
      this.$confirm({
        title: "是否确定重置 token ？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          return new Promise((resolve, reject) => {
            if (!store.userInfo || !store.userInfo.uid) {
              reject("没有用户信息");
              return;
            }
            const userId = store.userInfo.uid;
            updateApiToken(userId).then(res => {
              const userInfo = JSON.parse(JSON.stringify(store.userInfo));
              userInfo.apiToken = res.data.apiToken;
              store.userInfo = userInfo;
              this.$message.success("重置成功");
              resolve();
            });
          }).catch(err => {
            this.$message.error(err);
          });
        }
      });
    },
    /**
     * 复制
     */
    async copy(str) {
      try {
        await cliptext(str);
        this.$message.success("复制成功");
      } catch (error) {
        this.$message.error("复制失败");
      }
    }
  }
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.item {
  margin-top: 0.5em;
}
.desc-text {
  margin-right: 8px;
  display: inline-block;
  box-sizing: border-box;
  height: 26px;
  line-height: 24px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
</style>

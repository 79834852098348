import Vue from "vue";
import {
  getOpenapiInfo,
  getInvokeLog,
  updateOpenapiInfo
} from "@/api/datasheet";

class Openapi {
  constructor() {
    /**
     * @type {boolean} loading 是否初始化完成 openapi
     */
    this.loading = true;
    /**
     * @type {string} datasheetId 当前视图 id
     */
    this.datasheetId = null;
    /**
     * @type {object} datasheetInfo 当前视图的 openapi 基本信息
     */
    this.datasheetInfo = null;
    /**
     * @type {string} currentTabKey 当前的 tap key
     */
    this.currentTabKey = "request";
  }

  /**
   * 初始化
   * @param {string} datasheetId 视图 id
   */
  async init(datasheetId) {
    this.loading = true;
    if (!datasheetId) throw Error("没有 datasheetId");
    this.datasheetId = datasheetId;
    // 获取视图 openapi 基本信息
    let info = await getOpenapiInfo(this.datasheetId)
      .then(res => res.data || {})
      .catch(err => err.data || {});
    if (info.code) {
      return info;
    }
    info.apiPath = `/openapi/v1/datasheet/${this.datasheetId}/records`;
    this.datasheetInfo = info;
    this.loading = false;
  }

  /**
   * @type {string} baseURL 基础openapi 的 url
   */
  get baseURL() {
    return `${location.protocol}//${location.hostname}${
      location.port ? ":" + location.port : ""
    }`;
  }

  /**
   * 更新 ipWhitelist
   * @param {string} ips ip 白名单列表
   * @returns 请求结果
   */
  async updateIpWhitelist(ips) {
    try {
      const req = { ipWhitelist: ips };
      await updateOpenapiInfo(this.datasheetId, req);
      let info = JSON.parse(JSON.stringify(this.datasheetInfo));
      info.ipWhitelist = ips;
      this.datasheetInfo = info;
      return { code: 1 };
    } catch (err) {
      return err.data;
    }
  }

  /**
   * 获取调用列表
   * @param {object} params 请求参数
   * @returns 请求结果
   */
  getInvokeList(pagination = { current: 1, pageSize: 10 }) {
    const params = {
      page: pagination.current,
      size: pagination.pageSize
    };
    return getInvokeLog(this.datasheetId, params);
  }
}
const openapi = new Openapi();

export default Vue.observable(openapi);

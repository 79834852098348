export const columnsRequest = [
  {
    title: "参数名称",
    key: "name",
    dataIndex: "name",
    slots: { title: "customTitle" },
    scopedSlots: { customRender: "name" },
    align: "center",
    ellipsis: true,
    width: 110
  },
  {
    title: "解释",
    key: "description",
    dataIndex: "description",
    scopedSlots: { customRender: "description" },
    align: "center",
    ellipsis: true,
    width: 110
  },
  {
    title: "类型",
    key: "type",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
    align: "center",
    ellipsis: true,
    width: 110
  },
  {
    title: "是否必填",
    key: "required",
    dataIndex: "required",
    scopedSlots: { customRender: "required" },
    align: "center",
    ellipsis: true,
    width: 110
  },
  {
    title: "备注",
    key: "remark",
    dataIndex: "remark",
    scopedSlots: { customRender: "remark" },
    align: "center",
    ellipsis: true,
    width: 110
  },
  {
    title: "示例",
    key: "example",
    dataIndex: "example",
    scopedSlots: { customRender: "example" },
    align: "center",
    // ellipsis: true,
    width: 290
  }
];

export const columnsResponse = [
  {
    title: "参数名称",
    key: "name",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
    align: "center",
    ellipsis: true,
    width: 100
  },
  {
    title: "解释",
    key: "description",
    dataIndex: "description",
    scopedSlots: { customRender: "description" },
    align: "center",
    ellipsis: true,
    width: 100
  },
  {
    title: "类型",
    key: "type",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
    align: "center",
    ellipsis: true,
    width: 100
  },
  {
    title: "备注",
    key: "remark",
    dataIndex: "remark",
    scopedSlots: { customRender: "remark" },
    align: "center",
    ellipsis: true
  },
  {
    title: "示例",
    key: "example",
    dataIndex: "example",
    scopedSlots: { customRender: "example" },
    align: "center",
    ellipsis: true,
    width: 100
  }
];

export const columnsError = [
  {
    title: "错误码",
    key: "code",
    dataIndex: "code",
    scopedSlots: { customRender: "code" },
    align: "center",
    ellipsis: true,
    width: 100
  },
  {
    title: "报错原因",
    key: "reason",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    align: "center",
    ellipsis: true
  },
  {
    title: "建议解决措施",
    key: "resolve",
    dataIndex: "resolve",
    scopedSlots: { customRender: "resolve" },
    align: "center",
    ellipsis: true
  }
];

export const columnsRecord = [
  {
    title: "调用时间",
    key: "invokeTime",
    dataIndex: "invokeTime",
    scopedSlots: { customRender: "invokeTime" },
    align: "center",
    ellipsis: true,
    width: 200
  },
  {
    title: "调用 IP",
    key: "invokeIp",
    dataIndex: "invokeIp",
    scopedSlots: { customRender: "invokeIp" },
    align: "center",
    ellipsis: true,
    width: 150
  },
  {
    title: "请求结果",
    key: "result",
    dataIndex: "result",
    scopedSlots: { customRender: "result" },
    align: "center",
    ellipsis: true
  }
];

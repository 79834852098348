<template>
  <span class="btn-copy" @click="onClick">
    <a-icon v-if="icon" :type="icon" style="margin-right: 5px;" />
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    icon: String
  },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped lang="less">
@import (reference) "~@/assets/app.less";

.btn-copy {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 8px;
  margin-left: 5px;
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
  height: 26px;
  line-height: 24px;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: @blue-1;
  }
}
</style>

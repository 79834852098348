<template>
  <PaneHeader title="请求方式：GET">
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :scroll="{ x: 660 }"
      size="small"
    >
      <a-tooltip slot="name" slot-scope="text">
        <template slot="title">{{ text }}</template>
        <span>{{ text }}</span>
      </a-tooltip>
      <a-tooltip slot="description" slot-scope="text">
        <template slot="title">{{ text }}</template>
        <span>{{ text }}</span>
      </a-tooltip>
      <a-tooltip slot="type" slot-scope="text">
        <template slot="title">{{ text }}</template>
        <span>{{ text }}</span>
      </a-tooltip>
      <span slot="required" slot-scope="required, records">
        <a-tag :key="records.key" :color="required ? 'red' : ''">
          {{ required ? "是" : "否" }}
        </a-tag>
      </span>
      <a-tooltip slot="remark" slot-scope="text">
        <template slot="title">{{ text }}</template>
        <span>{{ text }}</span>
      </a-tooltip>
      <a-tooltip slot="example" slot-scope="text">
        <template>
          <template slot="title">{{
            text.text ? text.text + text.tips : text
          }}</template>
          <span>{{ text.text ? text.text : text }}</span>
          <span v-if="text.tips" style="color: #f5222d">{{ text.tips }}</span>
        </template>
      </a-tooltip>
    </a-table>
  </PaneHeader>
</template>

<script>
import PaneHeader from "./PaneHeader.vue";
import { columnsRequest } from "../resources/table-col";

const data = [
  {
    key: "accessToken",
    name: "accessToken",
    description: "API token",
    type: "string",
    required: true,
    remark: "位置：header",
    example: "tk160426e04f8b46fb9554905ad6755555"
  },
  {
    key: "datasheetId",
    name: "datasheetId",
    description: "工作表 ID",
    type: "string",
    required: true,
    remark: "位置：path",
    example: "sht1p86KCI5555"
  },
  {
    key: "page",
    name: "page",
    description: "当前页",
    type: "string",
    required: false,
    remark: "位置：query",
    example: "1"
  },
  {
    key: "size",
    name: "size",
    description: "页大小",
    type: "string",
    required: false,
    remark: "位置：query",
    example: "100"
  },
  {
    key: "filter",
    name: "filter",
    description: "筛选条件",
    type: "string",
    required: false,
    remark: "位置：query",
    example: {
      text: "{列名}='列值'、{列名}='列值' || {列名2}='列值2'，",
      tips: "需要用 URI 编码对该参数值进行编码，支持 || 、&& 操作符"
    }
  }
];

export default {
  components: { PaneHeader },
  data() {
    return {
      data,
      columns: columnsRequest
    };
  }
};
</script>

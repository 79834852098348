<template>
  <a-table
    :columns="columns"
    :data-source="data"
    :pagination="false"
    :scroll="{ x: 660 }"
    size="small"
  >
    <template v-for="tag in keys" :slot="tag" slot-scope="text">
      <a-tooltip :key="tag">
        <template slot="title">{{ text }}</template>
        <span>{{ text }}</span>
      </a-tooltip>
    </template>
  </a-table>
</template>

<script>
import { columnsError } from "../resources/table-col";

// "40000", "未知错误"
// "42003", "token无效"
// "42006", "不支持的请求方法！"
// "42007", "禁止访问！"
// "43002", "不合法的参数！"

const data = [
  {
    key: "1",
    code: "40000",
    reason: "未知错误",
    resolve: "联系客服"
  },
  {
    key: "2",
    code: "42003",
    reason: "token 无效",
    resolve: "检查 token 是否填错"
  },
  {
    key: "3",
    code: "42006",
    reason: "不支持的请求方法！",
    resolve: "检查是不是用错了请求方法"
  },
  {
    key: "4",
    code: "42007",
    reason: "禁止访问！",
    resolve: "检查 IP 白名单设置"
  },
  {
    key: "5",
    code: "43002",
    reason: "不合法的参数！",
    resolve: "检查参数"
  }
];

export default {
  data() {
    return {
      data,
      columns: columnsError,
      keys: ["code", "reason", "resolve"]
    };
  }
};
</script>

<template>
  <Pane style="width: 50%">
    <!-- 空数据提示 -->
    <a-empty
      v-if="isEmpty"
      :image="ImageEmpty"
      :imageStyle="{ height: '200px', marginTop: '50px' }"
    >
      <div slot="description" class="ndl-text-desc-small ndl-margin-top-lg">
        请选择一个视图。
        <a href="https://eiplatform.cn">
          <span>关于API </span>
          <i class="ndl-icon-share-forward-box-line"></i>
        </a>
      </div>
    </a-empty>
    <ApiDetail v-else />
  </Pane>
</template>

<script>
import ImageEmpty from "@/assets/images/ai_widget.svg";
import Pane from "../../pane-container";
import ApiDetail from "./ApiDetail";
import openapi from "./openapi";

export default {
  components: { Pane, ApiDetail },
  data() {
    return { ImageEmpty };
  },
  computed: {
    isEmpty() {
      return !this.$route.params.datasheetId;
    }
  },
  created() {
    this.initModel();
  },
  watch: {
    $route() {
      this.initModel();
    }
  },
  methods: {
    async initModel() {
      const datasheetId = this.$route.params.datasheetId;
      if (datasheetId) {
        openapi.init(datasheetId);
      }
    }
  }
};
</script>

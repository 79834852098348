<template>
  <a-skeleton v-if="loading" loading :active="true" :paragraph="{ rows: 5 }" />
  <div v-else class="api-detail">
    <!-- openapi 概览 -->
    <Header />
    <!-- openapi 详情 -->
    <section class="detail">
      <a-tabs
        :default-active-key="currentTabKey"
        :tab-bar-gutter="12"
        @change="changeTap"
      >
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tab">
          <component :is="item.component"></component>
        </a-tab-pane>
      </a-tabs>
    </section>
  </div>
</template>

<script>
import Header from "./components/Header";
import RequestPane from "./components/RequestPane";
import ResponsePane from "./components/ResponsePane";
import ExamplePane from "./components/ExamplePane";
import ErrorPane from "./components/ErrorPane";
import RecordPane from "./components/RecordPane";
import WhiteipPane from "./components/WhiteipPane";
import openapi from "./openapi";

const tabs = [
  { key: "request", tab: "请求参数", component: "RequestPane" },
  { key: "response", tab: "返回结果", component: "ResponsePane" },
  { key: "example", tab: "调用示例", component: "ExamplePane" },
  { key: "error", tab: "错误码", component: "ErrorPane" },
  { key: "record", tab: "调用记录", component: "RecordPane" },
  { key: "whiteip", tab: "IP白名单设置", component: "WhiteipPane" }
];

export default {
  name: "ApiDetail",
  components: {
    Header,
    RequestPane,
    ResponsePane,
    ExamplePane,
    ErrorPane,
    RecordPane,
    WhiteipPane
  },
  data() {
    return { tabs };
  },
  computed: {
    loading() {
      return openapi.loading;
    },
    currentTabKey() {
      return openapi.currentTabKey;
    }
  },
  methods: {
    changeTap(activeKey) {
      openapi.currentTabKey = activeKey;
    }
  }
};
</script>

<style lang="less" scoped>
.api-detail {
  margin: 10px auto;
  /deep/ .ant-table-thead th {
    background-color: #f7f7f7 !important;
    font-weight: 700;
  }
}
.detail {
  margin-top: 16px;
  padding: 0 12px 12px;
}
</style>

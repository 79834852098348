<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="pagination"
      :scroll="{ x: 660 }"
      size="small"
      :row-key="record => record.id"
      :loading="loading"
      @change="loadData"
    >
      <template v-for="tag in keys" :slot="tag" slot-scope="text">
        <a-tooltip :key="tag">
          <template slot="title">{{ text }}</template>
          <span>{{ text }}</span>
        </a-tooltip>
      </template>
    </a-table>
  </div>
</template>

<script>
import openapi from "../openapi";
import { columnsRecord } from "../resources/table-col";

export default {
  data() {
    return {
      dataSource: [],
      columns: columnsRecord,
      pagination: {},
      loading: false,
      keys: ["invokeTime", "invokeIp", "result"]
    };
  },
  mounted() {
    this.loadData({ current: 1, pageSize: 10 });
  },
  watch: {
    datasheetId() {
      this.loadData({ current: 1, pageSize: 10 });
    }
  },
  computed: {
    datasheetId() {
      return openapi.datasheetId;
    }
  },
  methods: {
    loadData(pagination) {
      this.loading = true;
      this.dataSource = [];
      openapi
        .getInvokeList(pagination)
        .then(res => {
          const data = res.data;
          data.list = data.list || [];
          this.pagination = { ...pagination, total: data.total };
          this.dataSource = data.list.map(item => {
            if (item.isInvokeSuccess == "1") {
              item.result = "请求成功";
            } else {
              item.result = `请求失败，错误码【${item.errorCode}】，${item.errorMsg}`;
            }
            return item;
          });
        })
        .catch(err => {
          const res = err.data || {};
          this.$message.error(`IP 白名单配置失败${res.code}: ${res.msg}`);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<template>
  <div>
    <PaneHeader
      v-for="example in examples"
      :key="example.code"
      :title="example.title"
      :showCopy="true"
      @copy="copy(example.code)"
    >
      <Codeblock :code="example.code" :type="example.type" />
    </PaneHeader>
  </div>
</template>

<script>
import cliptext from "copy-to-clipboard";
import PaneHeader from "./PaneHeader.vue";
import Codeblock from "./Codeblock";
import examples from "../resources/code-example.js";

export default {
  components: { PaneHeader, Codeblock },
  data() {
    return {
      examples: examples
    };
  },
  methods: {
    async copy(str) {
      try {
        await cliptext(str);
        this.$message.success("复制成功");
      } catch (error) {
        this.$message.error("复制失败");
      }
    }
  }
};
</script>

import openapi from "../openapi";

export const curlCode = `curl "${openapi.baseURL}/openapi/v1/datasheet/工作表id/records?size=1&page=1" -H "accessToken: 你的token"`;

export const javaCode = `// 需要添加 okhttp3 依赖
OkHttpClient httpClient = new OkHttpClient.Builder().build();
HttpUrl.Builder urlBuilder = HttpUrl.parse("${openapi.baseURL}/openapi/v1/datasheet/工作表id/records").newBuilder();
urlBuilder.addQueryParameter("size", "1");
urlBuilder.addQueryParameter("page", "1");
String url = urlBuilder.build().toString();
Request request = new Request.Builder()
  .get()
  .url(url)
  .addHeader("accessToken", "你的token")
  .build();
String result = httpClient.newCall(request).execute().body().string();
System.out.println(result);`;

export const pythonCode = `import requests
headers = { "accessToken": "你的token" }
response = requests.get("${openapi.baseURL}/openapi/v1/datasheet/工作表id/records?size=1&page=1", headers = headers)
print(response.json())`;

export const jsCode = `import axios from "axios";

axios.get("${openapi.baseURL}/openapi/v1/datasheet/工作表id/records", {
  timeout: 0,
  headers: { "accessToken": "你的token" },
  params: {
    size: 1,
    page: 1,
    // 自己拼接 url 时需要注意使用 encodeURIComponent 函数编码参数
    // 此处示例代码使用了 axios 库，默认会编码参数
    filter: "{列名}='列值' || {列名2}='列值2'"
  }
}).then(res => {
  console.info(res.data)
})`;

export const phpCode = `<?php
$data = array('size'=>'1', 'page'=>'1');
$url = '${openapi.baseURL}/openapi/v1/datasheet/工作表id/records';
$url = $url.'?'.http_build_query($data);
$headers = array('accessToken: 你的token');
$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, $url);
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, false);
curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, 0);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);
$response = curl_exec($ch);
curl_close($ch);
echo $response;
?>`;

export default [
  { title: "cURL", type: "bash", code: curlCode },
  { title: "Java", type: "java", code: javaCode },
  { title: "Python", type: "python", code: pythonCode },
  { title: "JavaScript", type: "javascript", code: jsCode },
  { title: "PHP", type: "php", code: phpCode }
];

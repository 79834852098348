<template>
  <pre class="codeblock" v-html="hlCode"></pre>
</template>

<script>
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
import java from "highlight.js/lib/languages/java";
import python from "highlight.js/lib/languages/python";
import javascript from "highlight.js/lib/languages/javascript";
import bash from "highlight.js/lib/languages/bash";
import php from "highlight.js/lib/languages/php";

hljs.registerLanguage("json", json);
hljs.registerLanguage("java", java);
hljs.registerLanguage("python", python);
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("bash", bash);
hljs.registerLanguage("php", php);

export default {
  props: {
    code: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "json"
    }
  },
  computed: {
    hlCode() {
      if (!this.code) {
        return null;
      }
      return hljs.highlight(this.code, {
        language: this.type
      }).value;
    }
  },
  methods: {}
};
</script>

<style lang="less" scoped>
// @import "~highlight.js/styles/atom-one-light.css";
@import "~highlight.js/styles/base16/bright.css";

.codeblock {
  border-radius: 4px;
  padding: 12px;
  background: #f7f7f7;
  margin: 0;
}
</style>

<template>
  <div>
    <PaneHeader title="成功返回码：200">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :scroll="{ x: 660 }"
        size="small"
      >
        <template v-for="tag in keys" :slot="tag" slot-scope="text">
          <a-tooltip :key="tag">
            <template slot="title">{{ text }}</template>
            <span>{{ text }}</span>
          </a-tooltip>
        </template>
      </a-table>
    </PaneHeader>
    <PaneHeader title="返回示例">
      <Codeblock :code="response" />
    </PaneHeader>
  </div>
</template>

<script>
import PaneHeader from "./PaneHeader.vue";
import Codeblock from "./Codeblock";

import { columnsResponse } from "../resources/table-col";
import exampleResponse from "../resources/response.json";

const data = [
  {
    key: "1",
    name: "code",
    description: "请求状态码",
    type: "string",
    remark: "无",
    example: "200"
  },
  {
    key: "2",
    name: "msg",
    description: "提示信息",
    type: "string",
    remark: "无",
    example: "数据获取成功"
  },
  {
    key: "3",
    name: "data",
    description: "返回的数据",
    type: "object",
    remark: "包括分页信息和数据",
    example: "无"
  },
  {
    key: "4",
    name: "data.total",
    description: "总数",
    type: "integer",
    remark: "无",
    example: "10"
  },
  {
    key: "5",
    name: "data.size",
    description: "页大小",
    type: "integer",
    remark: "无",
    example: "10"
  },
  {
    key: "6",
    name: "data.current",
    description: "当前页",
    type: "integer",
    remark: "无",
    example: "1"
  },
  {
    key: "7",
    name: "data.pages",
    description: "分页数",
    type: "integer",
    remark: "无",
    example: "1"
  },
  {
    key: "8",
    name: "data.list",
    description: "视图数据",
    type: "integer",
    remark: "视图的真实数据，每个元素为一行",
    example: "无"
  }
];

export default {
  components: { PaneHeader, Codeblock },
  data() {
    return {
      data,
      columns: columnsResponse,
      keys: ["name", "description", "type", "remark", "example"],
      response: JSON.stringify(exampleResponse, null, 2)
    };
  }
};
</script>

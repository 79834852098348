<template>
  <div>
    <a-textarea
      v-model="ips"
      :auto-size="{ minRows: 7, maxRows: 7 }"
      class="textarea"
    />
    <div class="footer">
      <span class="ndl-text-desc">
        多个 IP 用英文分号分隔，如没有填写默认允许所有 IP 请求
      </span>
      <a-button type="primary" @click="save">保存</a-button>
    </div>
  </div>
</template>

<script>
import openapi from "../openapi";

export default {
  data() {
    return {
      ips: ""
    };
  },
  watch: {
    datasheetInfo(value) {
      this.ips = value.ipWhitelist;
    }
  },
  computed: {
    datasheetInfo() {
      return openapi.datasheetInfo || "";
    }
  },
  mounted() {
    if (this.datasheetInfo.ipWhitelist) {
      this.ips = this.datasheetInfo.ipWhitelist;
    }
  },
  methods: {
    async save() {
      let res = await openapi.updateIpWhitelist(this.ips);
      if (res.code == 1) {
        this.$message.success("IP 白名单配置成功！");
      } else {
        this.$message.error(`IP 白名单配置失败${res.code}: ${res.msg}`);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
</style>
